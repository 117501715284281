@import '../../variables';

.Posts{
    article{
        background-color: white;
        margin-bottom: 2em;
        padding: 2em;
        div{
            text-align: justify;
        }
    }
}


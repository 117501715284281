@import '../../variables';

.Logo{
    align-self: flex-start;
    flex-grow: 0;
    padding: 1em;
}

.newGameForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-between;
    background-color: rgba(255,255,255,0.7);
    padding: 1em;
    padding-bottom: 2em;
    input, select{
        border-radius: 20px;
        padding: 0.5em;
        width: 30%;
        outline: none;
    }
    .label{
        padding-right: 1em;
        text-align: right;
        width:50%;
    }
    label{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        font-size: 1.1em;
        margin: 1em 0;
        user-select: none;
        cursor: pointer;
        .checkBox{
            height: 25px;
            width: 25px;
            background-color: white;
            border-radius: 10px;
            &:after {
                content: "";
                display: none;
            }
            &:after {
                margin-left: 9px;
                margin-top: 5px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
        &:hover input ~ .checkBox {
            background-color: #ccc;
        }
        input:checked ~ .checkBox {
            background-color: $primaryColor;
        }
        input:checked ~ .checkBox:after {
            display: block;
        }
    }
    .error{
        color: red;
        background-color: lightsalmon;
        border: 1px solid red;
        border-radius: 15px;
        padding: 0.5em;
    }
    input[type=checkbox]{
        opacity: 0;
        cursor: pointer;
        display: none;
    }
    
    button, a{
        padding: 1em;
        border-radius: 10px;
        border: 1px solid $primaryColor;
        background-color: $contrastColor;
        color: $primaryColor;
        font-size: 1.2em;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.6);
        outline: none;
        text-decoration: none;
        margin-left: 1em;
        &:hover{
            background-color: $thirdColor;
            box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.6);
        }
    }
}
@import '../../variables';

.profileView{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-wrap: nowrap;
    .profileCard{
        background-color: white;
        padding: 1em 3em;
        border-radius: 10px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.6);
        .profileimg{
            margin-top: -40%;
            border-radius:50%;
            position: relative;
        }
    }
}
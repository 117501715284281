@import '../../variables';

.gamesHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 100px;
    a{
        text-decoration: none;
        font-size: 1.5em;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    i{
        font-size: 2em;
        margin-right: 0.5em;
    }
}
.success{
    background-color: lightgreen;
    color: darkgreen;
    border: 1px solid darkgreen;
    padding: 0.5em;
    width: 95%;
    border-radius: 10px;
    margin: 0 auto;
}
.started{
    text-align: center;
}
.gameList{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    .gameCard{
        background-color: white;
        border-radius:10px;
        border: 1px solid $primaryColor;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.6);
        margin-bottom: 2em;
        a{
            color: $contrastColor;
            text-decoration: none;
        }
        .cardImage{
            margin: 0;
            padding: 0;
            border-radius: 10px 10px 0 0;
            background-color: $primaryColor;
            img{
                width: 100%;
                margin: 0;
                border-radius: 10px 10px 0 0;
                padding: 0;
            }
        }
        .cardHeader{
            margin: 0;
            padding: 0.1em;
            background-color: $primaryColor;
            color: $contrastColor;
        }
        .cardBody{
            padding: 0 1em;
            text-align: justify;
        }
        .cardFooter{
            display: flex;
            padding-bottom: 1.5em;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            button,
            a{
                color: $contrastColor;
                background-color: $primaryColor;
                border:1px solid $contrastColor;
                padding: 0.5em;
                border-radius: 10px;
                box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.6);
            }
            button{
                font-size: 1.1em;
            }
        }
        .fa-thumbs-up{
            color: green;
        }
        .fa-thumbs-down{
            color: red;
        }
    }
}
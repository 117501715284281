
@import '../../variables';
main{
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  flex-grow: 1;
  flex-wrap: nowrap;
  .mainLogo{
    padding-top: 30vh;
    img{
      margin: 0.5em;
      animation: App-logo-spin infinite 10s linear;
    }
  }
  .mainTitle{
    font-size: 2em;
  }
  .mainSection{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: space-around;
    padding: 0.5em;
    @media (max-width: 500px){
      flex-direction: column;
      align-items: stretch;
      padding: 0.5em 1em;
      a{
        margin-bottom: 1.5em;
      }
    }
  }
  .callToAction{
    padding: 1em;
    border-radius: 10px;
    border: 1px solid $primaryColor;
    background-color: $contrastColor;
    color: $primaryColor;
    font-size: 1.2em;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.6);
    outline: none;
    text-decoration: none;
    &:hover{
      background-color: $thirdColor;
      box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.6);
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
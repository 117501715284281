@import '../../variables';

.ModalBackground{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    z-index: 30;
    
    .ModalWindow{
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.6);
        min-width: 50%;
        .ModalHeader{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: $primaryColor;
            color: white;
            padding: 0em 1em;
            border-radius: 10px 10px 0 0;
            button{
                background-color: $primaryColor;
                border: none;
                color: white;
                font-size: 1.4em;
                padding: 0;
            }
        }
        .ModalBody{
            padding: 1em 2em;

            .error{
                color: darkred;
                background-color: lightsalmon;
                border: 1px solid red;
                border-radius: 15px;
                padding: 0.5em 1em;
                margin: 0.5em 0;
            }
            form{
                display: flex;
                flex-direction: row;
                justify-content: stretch;
                align-items: stretch;
                input{
                    padding: 0.5em 1em;
                    border-radius: 10px;
                    font-size: 1.1em;
                    flex-grow: 1;
                    margin-right: 0.5em;
                }
                button{
                    padding: 0.5em 2em;
                    border-radius: 10px;
                    border: 1px solid $primaryColor;
                    background-color: $contrastColor;
                    color: $primaryColor;
                    font-size: 1.1em;
                    outline: none;
                    &:hover{
                        background-color: $thirdColor;
                        box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.6);
                    }
                }
            }
        }
    }
}
@import '../../variables.scss';
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+SC|Noto+Serif+SC');

body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans SC', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primaryColor;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7,
.headtype{
  font-family: 'Noto Serif SC', serif;
}


a{
  color: $primaryColor;
  outline: none;
}

input,
button{
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  min-height: 100vh;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  @media (min-width: 800px){
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    &.toggled {
      padding-right: 250px;
    }
  }
}

@import url(https://fonts.googleapis.com/css?family=Noto+Sans+SC|Noto+Serif+SC);
main {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  flex-grow: 1;
  flex-wrap: nowrap; }
  main .mainLogo {
    padding-top: 30vh; }
    main .mainLogo img {
      margin: 0.5em;
      -webkit-animation: App-logo-spin infinite 10s linear;
              animation: App-logo-spin infinite 10s linear; }
  main .mainTitle {
    font-size: 2em; }
  main .mainSection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: space-around;
    padding: 0.5em; }
    @media (max-width: 500px) {
      main .mainSection {
        flex-direction: column;
        align-items: stretch;
        padding: 0.5em 1em; }
        main .mainSection a {
          margin-bottom: 1.5em; } }
  main .callToAction {
    padding: 1em;
    border-radius: 10px;
    border: 1px solid #69779b;
    background-color: #d7eaea;
    color: #69779b;
    font-size: 1.2em;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.6);
    outline: none;
    text-decoration: none; }
    main .callToAction:hover {
      background-color: #acdbdf;
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.6); }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.ModalBackground {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30; }
  .ModalBackground .ModalWindow {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.6);
    min-width: 50%; }
    .ModalBackground .ModalWindow .ModalHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: #69779b;
      color: white;
      padding: 0em 1em;
      border-radius: 10px 10px 0 0; }
      .ModalBackground .ModalWindow .ModalHeader button {
        background-color: #69779b;
        border: none;
        color: white;
        font-size: 1.4em;
        padding: 0; }
    .ModalBackground .ModalWindow .ModalBody {
      padding: 1em 2em; }
      .ModalBackground .ModalWindow .ModalBody .error {
        color: darkred;
        background-color: lightsalmon;
        border: 1px solid red;
        border-radius: 15px;
        padding: 0.5em 1em;
        margin: 0.5em 0; }
      .ModalBackground .ModalWindow .ModalBody form {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-items: stretch; }
        .ModalBackground .ModalWindow .ModalBody form input {
          padding: 0.5em 1em;
          border-radius: 10px;
          font-size: 1.1em;
          flex-grow: 1;
          margin-right: 0.5em; }
        .ModalBackground .ModalWindow .ModalBody form button {
          padding: 0.5em 2em;
          border-radius: 10px;
          border: 1px solid #69779b;
          background-color: #d7eaea;
          color: #69779b;
          font-size: 1.1em;
          outline: none; }
          .ModalBackground .ModalWindow .ModalBody form button:hover {
            background-color: #acdbdf;
            box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.6); }

.gamesHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 100px; }
  .gamesHeader a {
    text-decoration: none;
    font-size: 1.5em;
    display: flex;
    flex-direction: row;
    align-items: center; }
  .gamesHeader i {
    font-size: 2em;
    margin-right: 0.5em; }

.success {
  background-color: lightgreen;
  color: darkgreen;
  border: 1px solid darkgreen;
  padding: 0.5em;
  width: 95%;
  border-radius: 10px;
  margin: 0 auto; }

.started {
  text-align: center; }

.gameList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start; }
  .gameList .gameCard {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #69779b;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.6);
    margin-bottom: 2em; }
    .gameList .gameCard a {
      color: #d7eaea;
      text-decoration: none; }
    .gameList .gameCard .cardImage {
      margin: 0;
      padding: 0;
      border-radius: 10px 10px 0 0;
      background-color: #69779b; }
      .gameList .gameCard .cardImage img {
        width: 100%;
        margin: 0;
        border-radius: 10px 10px 0 0;
        padding: 0; }
    .gameList .gameCard .cardHeader {
      margin: 0;
      padding: 0.1em;
      background-color: #69779b;
      color: #d7eaea; }
    .gameList .gameCard .cardBody {
      padding: 0 1em;
      text-align: justify; }
    .gameList .gameCard .cardFooter {
      display: flex;
      padding-bottom: 1.5em;
      flex-direction: row;
      justify-content: space-around;
      align-items: center; }
      .gameList .gameCard .cardFooter button,
      .gameList .gameCard .cardFooter a {
        color: #d7eaea;
        background-color: #69779b;
        border: 1px solid #d7eaea;
        padding: 0.5em;
        border-radius: 10px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.6); }
      .gameList .gameCard .cardFooter button {
        font-size: 1.1em; }
    .gameList .gameCard .fa-thumbs-up {
      color: green; }
    .gameList .gameCard .fa-thumbs-down {
      color: red; }

.spinner {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px; }
  .spinner .path {
    stroke: #93bfec;
    stroke-linecap: round;
    -webkit-animation: dash 1.5s ease-in-out infinite;
            animation: dash 1.5s ease-in-out infinite; }

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124; } }

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124; } }

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-wrap: nowrap; }
  .login .modal {
    background-color: white;
    padding: 1em 3em;
    border-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.6); }
    .login .modal form {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      flex-grow: 1;
      align-items: stretch; }
      .login .modal form input {
        padding: 0.5em 1em;
        border-radius: 10px;
        margin: 0.4em 0;
        font-size: 1.1em; }
    .login .modal .error {
      color: darkred;
      background-color: lightsalmon;
      border: 1px solid red;
      border-radius: 15px;
      padding: 1em 1.5em;
      margin: 0.5em 0; }
    .login .modal button {
      padding: 0.5em 2em;
      border-radius: 10px;
      border: 1px solid #69779b;
      background-color: #d7eaea;
      color: #69779b;
      font-size: 1.1em;
      outline: none; }
      .login .modal button:hover {
        background-color: #acdbdf;
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.6); }

.gameDetail {
  background-color: white;
  padding: 2em 0; }
  .gameDetail .gamePlayers {
    border-top: 1px solid #69779b; }
  .gameDetail button {
    padding: 0.5em 1em;
    border-radius: 10px;
    border: 1px solid #69779b;
    background-color: #d7eaea;
    color: #69779b;
    font-size: 1.1em;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.6);
    outline: none;
    text-decoration: none;
    margin-left: 1em; }
    .gameDetail button:hover {
      background-color: #acdbdf;
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.6); }
  .gameDetail .fa-thumbs-up {
    color: green; }
  .gameDetail .fa-thumbs-down {
    color: red; }

.GameBoard {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden; }
  .GameBoard .GameHeader {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    padding-bottom: 1em; }
    .GameBoard .GameHeader .Turnnav {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end; }
      .GameBoard .GameHeader .Turnnav button {
        font-size: 1em;
        background-color: rgba(0, 0, 0, 0);
        color: #69779b;
        border: none; }
    .GameBoard .GameHeader #PlayersInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: left; }
    @media (min-width: 800px) {
      .GameBoard .GameHeader {
        flex-direction: row;
        justify-content: space-around;
        align-items: center; } }
  .GameBoard .Board {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; }
    .GameBoard .Board .Map {
      background-color: #DDDDDD;
      width: 837px;
      height: 768px;
      margin: 0;
      padding: 0;
      display: block;
      position: relative; }
      .GameBoard .Board .Map #map {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1; }
      .GameBoard .Board .Map .army, .GameBoard .Board .Map .fleet {
        position: absolute;
        z-index: 2; }
      .GameBoard .Board .Map .army {
        width: 40px; }
      .GameBoard .Board .Map .fleet {
        width: 50px; }
  @media (min-width: 1330px) {
    .GameBoard {
      align-items: stretch; }
      .GameBoard .Board {
        flex-direction: row;
        justify-content: space-around; }
      .GameBoard .Map {
        min-width: 837px; }
      .GameBoard .Orders {
        margin-top: 0; } }
  .GameBoard .Complements {
    align-self: stretch;
    flex-grow: 1; }
    .GameBoard .Complements .Messenger, .GameBoard .Complements .Orders {
      background-color: white;
      margin: 0 1em;
      margin-top: 1em;
      border-radius: 10px;
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.6);
      padding: 0.5em 1em;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch; }
      .GameBoard .Complements .Messenger .HeaderMessage, .GameBoard .Complements .Orders .HeaderMessage {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        flex-shrink: 1;
        flex-grow: 1;
        justify-content: stretch; }
        .GameBoard .Complements .Messenger .HeaderMessage button, .GameBoard .Complements .Orders .HeaderMessage button {
          border-radius: 10px 10px 0 0;
          border: 1px solid #d7eaea;
          background-color: #69779b;
          color: #d7eaea;
          flex-grow: 1;
          padding: 0.5em 0;
          font-size: 0.6em;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; }
          .GameBoard .Complements .Messenger .HeaderMessage button i, .GameBoard .Complements .Orders .HeaderMessage button i {
            font-size: 0.9em;
            margin-right: 0.4em; }
          .GameBoard .Complements .Messenger .HeaderMessage button .online, .GameBoard .Complements .Orders .HeaderMessage button .online {
            color: #90ee90; }
          .GameBoard .Complements .Messenger .HeaderMessage button .offline, .GameBoard .Complements .Orders .HeaderMessage button .offline {
            color: #f08080; }
          @media (min-width: 550px) {
            .GameBoard .Complements .Messenger .HeaderMessage button, .GameBoard .Complements .Orders .HeaderMessage button {
              font-size: 0.9em;
              display: inline;
              flex-direction: row; } }
        .GameBoard .Complements .Messenger .HeaderMessage .activeTab, .GameBoard .Complements .Orders .HeaderMessage .activeTab {
          border: 1px solid #69779b;
          background-color: #d7eaea;
          color: #69779b;
          border-bottom: 1px solid #d7eaea; }
      .GameBoard .Complements .Messenger .MessageView, .GameBoard .Complements .Orders .MessageView {
        background-color: #d7eaea;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: flex-start;
        padding-bottom: 1em;
        flex-grow: 1;
        border: 1px solid #69779b;
        border-top: none;
        min-height: 5em;
        max-height: 50vh;
        overflow-y: scroll; }
        .GameBoard .Complements .Messenger .MessageView p, .GameBoard .Complements .Orders .MessageView p {
          margin: 0;
          padding: 0.3em; }
        .GameBoard .Complements .Messenger .MessageView .self, .GameBoard .Complements .Orders .MessageView .self {
          align-self: flex-end;
          background-color: #acdbdf;
          padding: 0.3em 1em; }
      .GameBoard .Complements .Messenger #MessengerForm, .GameBoard .Complements .Orders #MessengerForm {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
        padding: 1em 0; }
        .GameBoard .Complements .Messenger #MessengerForm .to, .GameBoard .Complements .Orders #MessengerForm .to {
          display: flex;
          flex-direction: row;
          justify-content: stretch;
          align-items: center; }
          .GameBoard .Complements .Messenger #MessengerForm .to select, .GameBoard .Complements .Orders #MessengerForm .to select {
            margin-left: 0.5em;
            flex-grow: 1;
            padding: 0.5em;
            border-radius: 5px; }
        .GameBoard .Complements .Messenger #MessengerForm .MessageBox, .GameBoard .Complements .Orders #MessengerForm .MessageBox {
          display: flex;
          flex-direction: row;
          justify-content: stretch;
          align-items: center;
          padding-top: 0.5em; }
          .GameBoard .Complements .Messenger #MessengerForm .MessageBox input, .GameBoard .Complements .Orders #MessengerForm .MessageBox input {
            flex-grow: 1;
            padding: 1em;
            border-radius: 5px; }
          .GameBoard .Complements .Messenger #MessengerForm .MessageBox button, .GameBoard .Complements .Orders #MessengerForm .MessageBox button {
            padding: 0.5em 1em;
            border-radius: 10px;
            border: 1px solid #69779b;
            background-color: #d7eaea;
            color: #69779b;
            font-size: 1.1em;
            outline: none;
            text-decoration: none; }
            .GameBoard .Complements .Messenger #MessengerForm .MessageBox button:hover, .GameBoard .Complements .Orders #MessengerForm .MessageBox button:hover {
              background-color: #acdbdf;
              box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.6); }
      .GameBoard .Complements .Messenger .OrdersBody, .GameBoard .Complements .Orders .OrdersBody {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch; }
        .GameBoard .Complements .Messenger .OrdersBody .orderItem, .GameBoard .Complements .Orders .OrdersBody .orderItem {
          display: flex;
          flex-direction: column;
          justify-content: stretch;
          align-items: stretch;
          margin-top: 0.5em;
          border: 1px solid #69779b;
          border-radius: 10px;
          padding: 0.5em 0em; }
          .GameBoard .Complements .Messenger .OrdersBody .orderItem select, .GameBoard .Complements .Orders .OrdersBody .orderItem select {
            margin: 0 0.5em;
            flex-grow: 1;
            padding: 0.5em;
            border-radius: 5px;
            outline: none; }
        @media (min-width: 550px) {
          .GameBoard .Complements .Messenger .OrdersBody .orderItem, .GameBoard .Complements .Orders .OrdersBody .orderItem {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 0.5em;
            border: none;
            padding: 0; } }
      .GameBoard .Complements .Messenger button, .GameBoard .Complements .Orders button {
        margin-top: 0.5em;
        padding: 0.5em 1em;
        border-radius: 10px;
        border: 1px solid #69779b;
        background-color: #d7eaea;
        color: #69779b;
        font-size: 1.1em;
        outline: none;
        text-decoration: none; }
        .GameBoard .Complements .Messenger button:hover, .GameBoard .Complements .Orders button:hover {
          background-color: #acdbdf;
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.6); }

.notFound {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-content: stretch;
  font-size: 1.5em;
  height: 70vh; }

.Logo {
  align-self: flex-start;
  flex-grow: 0;
  padding: 1em; }

.newGameForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 1em;
  padding-bottom: 2em; }
  .newGameForm input, .newGameForm select {
    border-radius: 20px;
    padding: 0.5em;
    width: 30%;
    outline: none; }
  .newGameForm .label {
    padding-right: 1em;
    text-align: right;
    width: 50%; }
  .newGameForm label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 1.1em;
    margin: 1em 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer; }
    .newGameForm label .checkBox {
      height: 25px;
      width: 25px;
      background-color: white;
      border-radius: 10px; }
      .newGameForm label .checkBox:after {
        content: "";
        display: none; }
      .newGameForm label .checkBox:after {
        margin-left: 9px;
        margin-top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }
    .newGameForm label:hover input ~ .checkBox {
      background-color: #ccc; }
    .newGameForm label input:checked ~ .checkBox {
      background-color: #69779b; }
    .newGameForm label input:checked ~ .checkBox:after {
      display: block; }
  .newGameForm .error {
    color: red;
    background-color: lightsalmon;
    border: 1px solid red;
    border-radius: 15px;
    padding: 0.5em; }
  .newGameForm input[type=checkbox] {
    opacity: 0;
    cursor: pointer;
    display: none; }
  .newGameForm button, .newGameForm a {
    padding: 1em;
    border-radius: 10px;
    border: 1px solid #69779b;
    background-color: #d7eaea;
    color: #69779b;
    font-size: 1.2em;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.6);
    outline: none;
    text-decoration: none;
    margin-left: 1em; }
    .newGameForm button:hover, .newGameForm a:hover {
      background-color: #acdbdf;
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.6); }

.Posts article {
  background-color: white;
  margin-bottom: 2em;
  padding: 2em; }
  .Posts article div {
    text-align: justify; }

.profileView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-wrap: nowrap; }
  .profileView .profileCard {
    background-color: white;
    padding: 1em 3em;
    border-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.6); }
    .profileView .profileCard .profileimg {
      margin-top: -40%;
      border-radius: 50%;
      position: relative; }

.Background {
  background-image: url(/static/media/MainBackground.11d437c1.gif);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;
  padding-right: 0;
  -webkit-filter: blur(0px);
          filter: blur(0px);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease; }
  .Background.toggled {
    -webkit-filter: blur(8px);
            filter: blur(8px);
    z-index: 20;
    width: 100wh;
    height: 100vh;
    color: #fff;
    background: -webkit-linear-gradient(135deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
    background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
    background-size: 400% 400%;
    -webkit-animation: Gradient 10s ease infinite;
    animation: Gradient 10s ease infinite; }
  @media (min-width: 800px) {
    .Background {
      background-image: url(/static/media/Main_Background.d8431653.jpg); }
      .Background.toggled {
        right: 250px; } }

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@keyframes Gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

/*-------------------------------*/
/*     Sidebar nav styles        */
/*-------------------------------*/
.sideBar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  opacity: 0;
  background-color: #69779b;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: #d7eaea;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch; }
  .sideBar ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    margin: 0;
    padding: 0;
    height: 100%; }
    .sideBar ul li {
      text-align: left;
      padding: 0.8em 1.5em;
      margin: 0;
      position: relative; }
    .sideBar ul li:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      height: 100%;
      width: 3px;
      -webkit-transition: width .2s ease-in;
      transition: width .2s ease-in; }
    .sideBar ul li:hover:before {
      width: 100%;
      -webkit-transition: width .2s ease-in;
      transition: width .2s ease-in; }
    .sideBar ul li.active,
    .sideBar ul li:before {
      background-color: #7281A8; }
    .sideBar ul .homeLink.active,
    .sideBar ul .homeLink:before {
      background-color: #9DB2E7; }
    .sideBar ul .gamesLink.active,
    .sideBar ul .gamesLink:before {
      background-color: #415FAC; }
    .sideBar ul .blogLink.active,
    .sideBar ul .blogLink:before {
      background-color: #586381; }
    .sideBar ul .rulesLink.active,
    .sideBar ul .rulesLink:before {
      background-color: #3E465B; }
    .sideBar ul .forumLink.active,
    .sideBar ul .forumLink:before {
      background-color: #5780E7; }
    .sideBar ul .profileLink.active,
    .sideBar ul .profileLink:before {
      background-color: #465068; }
    .sideBar ul .sidebarBrand {
      padding: 1.2em 1.5em;
      font-size: 1.5em; }
    .sideBar ul .sidebarBrand:before {
      background-color: #69779b; }
    .sideBar ul .login {
      margin-top: auto;
      flex-grow: 0; }
  .sideBar a {
    color: #d7eaea;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; }
  .sideBar i, .sideBar img {
    margin-right: 0.5em; }
  .sideBar .profileimg {
    width: 30px;
    border-radius: 50px;
    border: 1px solid white; }
  .sideBar.active {
    opacity: 1;
    z-index: 20; }

/*-------------------------------*/
/*       Hamburger-Cross         */
/*-------------------------------*/
.hamburger {
  position: fixed;
  top: 20px;
  right: 15px;
  z-index: 999;
  display: block;
  width: 32px;
  height: 32px;
  background: transparent;
  border: none;
  -webkit-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

.hamburger.is-open {
  right: 265px; }

.hamburger:hover,
.hamburger:focus,
.hamburger:active {
  outline: none; }

.hamburger.is-closed:before {
  content: '';
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0; }

.hamburger.is-closed:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px, 0, 0);
          transform: translate3d(-100px, 0, 0);
  -webkit-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom,
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  position: absolute;
  left: 0;
  height: 4px;
  width: 100%; }

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom {
  background-color: #69779b; }

.hamburger.is-closed .hamb-top {
  top: 5px;
  -webkit-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

.hamburger.is-closed .hamb-middle {
  top: 50%;
  margin-top: -2px; }

.hamburger.is-closed .hamb-bottom {
  bottom: 5px;
  -webkit-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

.hamburger.is-closed:hover .hamb-top {
  top: 0;
  -webkit-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

.hamburger.is-closed:hover .hamb-bottom {
  bottom: 0;
  -webkit-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  background-color: #69779b; }

.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-bottom {
  top: 50%;
  margin-top: -2px; }

.hamburger.is-open .hamb-top {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transition: transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transition: transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08), -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08); }

.hamburger.is-open .hamb-middle {
  display: none; }

.hamburger.is-open .hamb-bottom {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transition: transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transition: transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08), -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08); }

.hamburger.is-open:before {
  content: '';
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

.hamburger.is-open:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px, 0, 0);
          transform: translate3d(-100px, 0, 0);
  -webkit-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

.register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex-grow: 1;
  flex-wrap: nowrap; }
  .register .modal {
    background-color: white;
    padding: 1em 3em;
    border-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.6); }
    .register .modal form {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      flex-grow: 1;
      align-items: stretch; }
      .register .modal form input {
        padding: 0.5em 1em;
        border-radius: 10px;
        margin: 0.4em 0;
        font-size: 1.1em; }
      .register .modal form .field {
        display: flex;
        flex-direction: row;
        justify-content: stretch; }
        .register .modal form .field input {
          flex-grow: 1; }
        .register .modal form .field button {
          padding: 0 3em; }
    .register .modal .error {
      color: darkred;
      background-color: lightsalmon;
      border: 1px solid red;
      border-radius: 15px;
      padding: 1em 1.5em;
      margin: 0.5em 0; }
    .register .modal button {
      padding: 0.5em 2em;
      border-radius: 10px;
      border: 1px solid #69779b;
      background-color: #d7eaea;
      color: #69779b;
      font-size: 1.1em;
      outline: none; }
      .register .modal button:hover {
        background-color: #acdbdf;
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.6); }

.notFound {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-content: stretch;
  font-size: 1.5em;
  height: 70vh; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans SC', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #69779b; }

h1,
h2,
h3,
h4,
h5,
h6,
h7,
.headtype {
  font-family: 'Noto Serif SC', serif; }

a {
  color: #69779b;
  outline: none; }

input,
button {
  outline: none; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.App {
  text-align: center;
  min-height: 100vh;
  padding-right: 0;
  display: flex;
  flex-direction: column; }
  @media (min-width: 800px) {
    .App {
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease; }
      .App.toggled {
        padding-right: 250px; } }


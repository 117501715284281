@import '../../../variables';

.login{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-wrap: nowrap;
    .modal{
        background-color: white;
        padding: 1em 3em;
        border-radius: 10px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.6);
        form{
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            flex-grow: 1;
            align-items: stretch;
            input{
                padding: 0.5em 1em;
                border-radius: 10px;
                margin: 0.4em 0;
                font-size: 1.1em;
            }
        }
        .error{
            color: darkred;
            background-color: lightsalmon;
            border: 1px solid red;
            border-radius: 15px;
            padding: 1em 1.5em;
            margin: 0.5em 0;
        }
        button{
            padding: 0.5em 2em;
            border-radius: 10px;
            border: 1px solid $primaryColor;
            background-color: $contrastColor;
            color: $primaryColor;
            font-size: 1.1em;
            outline: none;
            &:hover{
                background-color: $thirdColor;
                box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.6);
            }
        }
    }
}
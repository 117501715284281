@import '../../variables';

.gameDetail{
    background-color: white;
    padding: 2em 0;
    .gamePlayers{
        border-top: 1px solid $primaryColor;
    }
    button{
        padding: 0.5em 1em;
        border-radius: 10px;
        border: 1px solid $primaryColor;
        background-color: $contrastColor;
        color: $primaryColor;
        font-size: 1.1em;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.6);
        outline: none;
        text-decoration: none;
        margin-left: 1em;
        &:hover{
            background-color: $thirdColor;
            box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.6);
        }
    }
    .fa-thumbs-up{
        color: green;
    }
    .fa-thumbs-down{
        color: red;
    }
}
.GameBoard{
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    overflow: hidden;
    .GameHeader{
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
        padding-bottom: 1em;
        .Turnnav{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            button{
                font-size: 1em;
                background-color: rgba(0,0,0,0);
                color: $primaryColor;
                border: none;
            }
        }
        #PlayersInfo{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: left;
        }
        @media(min-width:800px){
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
        }
    }
    .Board{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        .Map{
            background-color:#DDDDDD;
            width: 837px;
            height: 768px;
            margin: 0;
            padding: 0;
            display: block;
            position: relative;
            #map{
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                z-index: 1;
            }
            .army, .fleet{
                position: absolute;
                z-index: 2;
            }
            .army{
                width: 40px;
            }
            .fleet{
                width: 50px;
            }
        }
    }
    @media (min-width:1330px){
        align-items: stretch;
        .Board{
            flex-direction: row;
            justify-content: space-around;
        }
        .Map{
            min-width: 837px;
        }
        .Orders{
            margin-top: 0;
        }
    }
    .Complements{
        align-self: stretch;
        flex-grow: 1;
        .Messenger, .Orders{
            background-color: white;
            margin: 0 1em;
            margin-top: 1em;
            border-radius: 10px;
            box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.6);
            padding: 0.5em 1em;
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            align-items: stretch;
            .HeaderMessage{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                flex-shrink: 1;
                flex-grow: 1;
                justify-content: stretch;
                button{
                    border-radius: 10px 10px 0 0;
                    border: 1px solid $contrastColor;
                    background-color: $primaryColor;
                    color: $contrastColor;
                    flex-grow: 1;
                    padding: 0.5em 0;
                    font-size: 0.6em;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    i{  
                        font-size:0.9em;
                        margin-right: 0.4em;
                    }
                    .online{
                        color: #90ee90;
                    }
                    .offline{
                        color: #f08080;
                    }
                    @media(min-width:550px){
                        font-size: 0.9em;
                        display: inline;
                        flex-direction: row;
                    }
                }
                .activeTab{
                    border: 1px solid $primaryColor;
                    background-color: $contrastColor;
                    color: $primaryColor;
                    border-bottom: 1px solid $contrastColor;
                }
            }
            .MessageView{
                background-color: $contrastColor;
                display: flex;
                flex-direction: column;
                justify-content: stretch;
                align-items: flex-start;
                padding-bottom: 1em;
                flex-grow: 1;
                border: 1px solid $primaryColor;
                border-top: none;
                min-height: 5em;
                max-height: 50vh;
                overflow-y: scroll;
                p{
                    margin: 0;
                    padding: 0.3em;
                }
                .self{
                    align-self:flex-end;
                    background-color: $thirdColor;
                    padding: 0.3em 1em;
                }
            }
            #MessengerForm{
                display: flex;
                flex-direction: column;
                justify-content: stretch;
                align-items: stretch;
                padding: 1em 0;
                .to{
                    display: flex;
                    flex-direction: row;
                    justify-content: stretch;
                    align-items: center;
                    select{
                        margin-left: 0.5em;
                        flex-grow: 1;
                        padding: 0.5em;
                        border-radius: 5px;
                    }
                }
                .MessageBox{
                    display: flex;
                    flex-direction: row;
                    justify-content: stretch;
                    align-items: center;
                    padding-top: 0.5em;
                    input{
                        flex-grow: 1;
                        padding: 1em;
                        border-radius: 5px;
                    }
                    button{
                        padding: 0.5em 1em;
                        border-radius: 10px;
                        border: 1px solid $primaryColor;
                        background-color: $contrastColor;
                        color: $primaryColor;
                        font-size: 1.1em;
                        outline: none;
                        text-decoration: none;
                        &:hover{
                            background-color: $thirdColor;
                            box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.6);
                        }

                    }
                }
            }
            .OrdersBody{
                display: flex;
                flex-direction: column;
                justify-content: stretch;
                align-items: stretch;
                .orderItem{
                    display: flex;
                    flex-direction: column;
                    justify-content: stretch;
                    align-items: stretch;
                    margin-top: 0.5em;
                    border: 1px solid $primaryColor;
                    border-radius: 10px;
                    padding: 0.5em 0em;
                    select{
                        margin: 0 0.5em;
                        flex-grow: 1;
                        padding: 0.5em;
                        border-radius: 5px;
                        outline: none;
                    }
                }
                @media (min-width:550px){
                    .orderItem{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 0.5em;
                        border: none;
                        padding: 0;
                    }
                }
            }
            button{
                margin-top: 0.5em;
                padding: 0.5em 1em;
                border-radius: 10px;
                border: 1px solid $primaryColor;
                background-color: $contrastColor;
                color: $primaryColor;
                font-size: 1.1em;
                outline: none;
                text-decoration: none;
                &:hover{
                    background-color: $thirdColor;
                    box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.6);
                }

            }
        }
    }
}